<template>
  <div>
    <img src="../../assets/img/beijing.png" alt="" class="bg_img" />
    <!-- 头部 -->
    <div class="head_box flex justify-between">
      <img src="../../assets/img/toubu.png" alt="" class="line_img" />
      <!-- 左侧 -->
      <div class="flex align-center title_box">
        <div class="flex align-center">
          <img src="../../assets/img/dianliang.png" alt="" class="dianliang" />
          <div style="position: relative">
            <img src="../../assets/img/title.png" alt="" class="title_img" />
            <div class="address">{{ street.streetName }}</div>
          </div>
          <img src="../../assets/img/dianliang.png" alt="" class="dianliang" />
        </div>
      </div>
      <!-- 中间 -->
      <div class="flex six_box">
        <div class="one_box flex align-center">
          <div style="color: #00ccd0; font-family: cursive; font-size: 20px">
            {{ UserNum.totalHousehold || 0 }}
          </div>
          <div style="color: #fff; font-size: 12px">总户数</div>
          <div class="left_top"></div>
          <div class="right_top"></div>
          <div class="left_bottom"></div>
          <div class="right_bottom"></div>
        </div>
        <div class="one_box flex align-center">
          <div style="color: #00ea9b; font-family: cursive; font-size: 20px">
            {{ UserNum.numRegisteredHouseholds || 0 }}
          </div>
          <div style="color: #fff; font-size: 12px">已注册户数</div>
          <div class="left_top"></div>
          <div class="right_top"></div>
          <div class="left_bottom"></div>
          <div class="right_bottom"></div>
        </div>
        <div class="one_box flex align-center">
          <div style="color: #fd56b1; font-family: cursive; font-size: 20px">
            {{ UserNum.totalNum || 0 }}
          </div>
          <div style="color: #fff; font-size: 12px">总人数</div>
          <div class="left_top"></div>
          <div class="right_top"></div>
          <div class="left_bottom"></div>
          <div class="right_bottom"></div>
        </div>
        <div class="one_box flex align-center">
          <div style="color: #00abe3; font-family: cursive; font-size: 20px">
            {{ UserNum.suppliersCount || 0 }}
          </div>
          <div style="color: #fff; font-size: 12px">服务商数</div>
          <div class="left_top"></div>
          <div class="right_top"></div>
          <div class="left_bottom"></div>
          <div class="right_bottom"></div>
        </div>
        <div class="one_box flex align-center">
          <div style="color: #f28b0c; font-family: cursive; font-size: 20px">
            {{ UserNum.activitiesCount || 0 }}
          </div>
          <div style="color: #fff; font-size: 12px">活动次数</div>
          <div class="left_top"></div>
          <div class="right_top"></div>
          <div class="left_bottom"></div>
          <div class="right_bottom"></div>
        </div>
        <div class="one_box flex align-center">
          <div style="color: #cbce39; font-family: cursive; font-size: 20px">
            {{ UserNum.serverCount || 0 }}
          </div>
          <div style="color: #fff; font-size: 12px">服务次数</div>
          <div class="left_top"></div>
          <div class="right_top"></div>
          <div class="left_bottom"></div>
          <div class="right_bottom"></div>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="flex align-center right_box" style="color: #b5c1d2; font-size: 12px">
        <table>
          <tr>
            <!-- <td>2021-12-30</td>
            <td>14:22:22 周四</td> -->
            <td></td>
            <td>
              <el-select v-model="nowStreet" @change="chooseStreet">
                <el-option v-for="item in streetList" :key="item.id" :label="item.streetName"
                  :value="JSON.stringify(item)"></el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <td>
              <el-select v-model="nowCommunity" @change="chooseCommunity">
                <el-option v-for="item in communityList" :key="item.id" :label="item.communityName"
                  :value="JSON.stringify(item)"></el-option>
              </el-select>
            </td>
            <td>
              <el-select v-model="nowVillage" @change="chooseVillage">
                <el-option v-for="item in villageList" :key="item.id" :label="item.villageName"
                  :value="JSON.stringify(item)"></el-option>
              </el-select>
            </td>
            <td>
              <el-select v-model="nowGrid" @change="chooseGrid">
                <el-option v-for="item in gridList" :key="item.id" :label="item.gridName" :value="JSON.stringify(item)">
                </el-option>
              </el-select>
            </td>
          </tr>
        </table>

        <div @click="search()">
          <img src="../../assets/img/Search.png" style="width: 25px" alt="" />
        </div>
      </div>
    </div>
    <!-- 数据 -->
    <div class="data_box">
      <!-- 第一列 -->
      <div class="flex" style="margin-bottom: 15px">
        <!-- 左 -->
        <div style="width: 25%" class="one_box">
          <div class="flex align-center echarts_title">
            <div style="color: #fff">月活动统计</div>
            <img src="../../assets/img/dianliang.png" alt="" />
          </div>
          <div id="leftTop" style="width: 100%; height: 270px"></div>
          <div class="left_top"></div>
          <div class="right_top"></div>
          <div class="left_bottom"></div>
          <div class="right_bottom"></div>
        </div>
        <!-- 中 -->
        <div style="width: 50%" class="map_box">
          <img src="../../assets/img/Frame.png" alt="" class="map_title" />
          <div class="" id="container" style="width: 100%; height: 100%"></div>
          <div class="left_top"></div>
          <div class="right_top"></div>
          <div class="left_bottom"></div>
          <div class="right_bottom"></div>
        </div>
        <!-- 右 -->
        <div style="width: 25%" class="one_box">
          <div class="flex align-center echarts_title">
            <div style="color: #fff">月服务统计</div>
            <img src="../../assets/img/dianliang.png" alt="" />
          </div>
          <div id="rightTop" style="width: 100%; height: 270px"></div>
          <div class="left_top"></div>
          <div class="right_top"></div>
          <div class="left_bottom"></div>
          <div class="right_bottom"></div>
        </div>
      </div>
      <!-- 第二列 -->
      <div class="flex">
        <!-- 1 -->
        <div style="width: 25%" class="one_box">
          <div class="flex align-center echarts_title">
            <div style="color: #fff">活动统计</div>
            <img src="../../assets/img/dianliang.png" alt="" />
          </div>
          <div id="one" style="width: 100%; height: 270px"></div>
          <div class="left_top"></div>
          <div class="right_top"></div>
          <div class="left_bottom"></div>
          <div class="right_bottom"></div>
        </div>
        <!-- 2 -->
        <div style="width: 25%" class="one_box">
          <div class="flex align-center echarts_title">
            <div style="color: #fff">年龄段统计</div>
            <img src="../../assets/img/dianliang.png" alt="" />
          </div>
          <div id="two" style="width: 100%; height: 270px"></div>
          <div class="left_top"></div>
          <div class="right_top"></div>
          <div class="left_bottom"></div>
          <div class="right_bottom"></div>
        </div>
        <!-- 3 -->
        <div style="width: 25%" class="one_box">
          <div class="flex align-center echarts_title">
            <div style="color: #fff">性别统计</div>
            <img src="../../assets/img/dianliang.png" alt="" />
          </div>
          <div id="three" style="width: 100%; height: 270px"></div>
          <div class="left_top"></div>
          <div class="right_top"></div>
          <div class="left_bottom"></div>
          <div class="right_bottom"></div>
        </div>
        <!-- 4 -->
        <div style="width: 25%" class="one_box">
          <div class="flex align-center echarts_title">
            <div style="color: #fff">月SOS统计</div>
            <img src="../../assets/img/dianliang.png" alt="" />
          </div>
          <div id="four" style="width: 100%; height: 270px"></div>
          <div class="left_top"></div>
          <div class="right_top"></div>
          <div class="left_bottom"></div>
          <div class="right_bottom"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const echarts = require("echarts");
import icon from "../../assets/img/position.png";
export default {
  data () {
    return {

      // 街道列表
      streetList: [],
      // 选中的街道
      street: {},
      // 当前选中的街道名
      nowStreet: "请选择街道",
      // 社区列表
      communityList: [],
      // 选中的社区
      community: {},
      // 选中的社区名
      nowCommunity: "请选择社区",
      // 小区列表
      villageList: [],
      // 选中的小区
      village: {},
      // 选中的小区名
      nowVillage: "请选择小区",
      // 网格列表
      gridList: [],
      // 选中的网格
      grid: {},
      // 选中的网格名
      nowGrid: "请选择网格",
      // 地图中心
      center: [],
      map: {},
      zoom: 15,
      // 用户数量
      UserNum: {}
    };
  },
  created () {
    // this.getTime();
    // window.addEventListener("resize", this.getHeight);
  },
  mounted () {
    this.loadBaseInfo();
  },
  // beforeDestroy() {
  //   clearInterval(this.timeInterval);
  //   this.timeInterval = null;
  // },
  // destroyed() {
  //   window.removeEventListener("resize", this.getHeight);
  // },

  methods: {
    // getDate() {
    //   this.timeInterval = setInterval(() => {
    //     this.dateNow = new Date();
    //     this.nowNum++;
    //   }, 1000);
    // },

    // 查询街道列表
    loadBaseInfo () {
      let that = this;
      that.$http
        .post("/street/queryStreetList", {
          userCode: localStorage.getItem("userCode"),
          // loginName: localStorage.getItem("login_name")
          phone: localStorage.getItem("phone")
        })
        .then(function (response) {
          if (response.data.code == 200) {
            that.streetList = response.data.data;
            that.street = response.data.data[0];
            that.nowStreet = response.data.data[0].streetName;
            that.pathList = response.data.data[0].path;
            that.center = [
              response.data.data[0].longitude,
              response.data.data[0].latitude
            ];
            that.getUserNum();
            that.initChart();
            that.getCommunityList();
            that.getVillageList();
            that.getGridList();
            that.initMap();
          }
        });
    },
    // 选择街道(重载图表，用户统计和地图)
    chooseStreet (e) {
      // console.log(JSON.parse(e));
      this.street = JSON.parse(e);
      this.nowStreet = this.street.streetName;
      this.center = [this.street.longitude, this.street.latitude];
      this.pathList = this.street.path;
      this.nowGrid = "";
      this.nowCommunity = "";
      this.nowVillage = "";
      this.getUserNum();
      this.initChart();

      this.getCommunityList();
      this.getVillageList();
      this.getGridList();
      this.initMap();
    },
    // 查询社区列表
    getCommunityList () {
      console.log(this.street.streetCode);
      this.$http
        .post("/street/queryCommunityList", {
          userCode: localStorage.getItem("userCode"),
          streetCode: this.street.streetCode
        })
        .then(res => {
          if (res.data.code == 200) {
            this.communityList = res.data.data;
          }
        });
    },
    // 选择社区
    chooseCommunity (e) {
      this.community = JSON.parse(e);
      this.nowCommunity = this.community.communityName;
      this.nowGrid = "";
      this.nowVillage = "";
      this.center = [this.community.longitude, this.community.latitude];
      this.pathList = this.community.path;
      this.initMap();
    },
    // 查询小区列表
    getVillageList () {
      this.$http
        .post("/street/queryVillageList", {
          userCode: localStorage.getItem("userCode"),
          streetCode: this.street.streetCode
        })
        .then(res => {
          if (res.data.code == 200) {
            this.villageList = res.data.data;
          }
        });
    },
    // 选择小区
    chooseVillage (e) {
      this.village = JSON.parse(e);
      this.nowVillage = this.village.villageName;
      this.nowGrid = "";
      this.nowCommunity = "";
      this.center = [this.village.longitude, this.village.latitude];
      this.pathList = this.village.path;
      this.initMap();
    },
    // 查询网格列表
    getGridList () {
      this.$http
        .post("/street/queryGridList", {
          userCode: localStorage.getItem("userCode"),
          streetCode: this.street.streetCode
        })
        .then(res => {
          if (res.data.code == 200) {
            this.gridList = res.data.data;
          }
        });
    },
    // 选择网格
    chooseGrid (e) {
      this.grid = JSON.parse(e);
      this.nowGrid = this.grid.gridName;
      this.nowCommunity = "";
      this.nowVillage = "";
      this.center = [this.grid.longitude, this.grid.latitude];
      this.pathList = this.grid.path;
      this.initMap();
    },
    // 加载图表
    initChart () {
      this.leftTop();
      this.rightTop();
      this.one();
      this.two();
      this.three();
      this.four();
    },
    // 左上角
    leftTop () {
      var that = this;
      that.$http
        .post("/memberActivity/monthActivityStastic", {
          streetCode: that.street.streetCode
        })
        .then(function (response) {
          if (response.data.code == 200) {
            var dayList = response.data.data.dayList;
            var numList = response.data.data.numList;
            var leftTop = echarts.init(document.getElementById("leftTop"));
            // 指定图表的配置项和数据
            let option = {
              // 图例
              legend: {
                data: ["活动数"],
                top: "25",
                x: "40%",
                textStyle: {
                  color: "#fff",
                  fontSize: 10
                },
                borderColor: "#ffe814",
                type: "plain",
                icon: "roundRect",
                tooltip: {
                  show: true
                }
              },
              grid: {
                left: "15%",
                right: "5%"
              },
              xAxis: {
                type: "category",
                axisLabel: {
                  color: "#6f7aa3"
                },
                axisLine: {
                  //轴线
                  lineStyle: {
                    color: "#747fa7"
                  }
                },
                axisTick: {
                  show: true, //展示刻度线
                  alignWithLabel: true, //刻度居中
                  lineStyle: {
                    //刻度线样式
                    color: "#747fa7"
                  },
                  interval: "0"
                },
                boundaryGap: false,
                data: dayList
              },
              yAxis: {
                axisLabel: {
                  color: "#747fa7"
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "#183578"
                  }
                },
                axisLine: {
                  //轴线
                  lineStyle: {
                    color: "#747fa7"
                  }
                }
              },
              series: [
                {
                  name: "活动数",
                  symbolSize: 7,
                  //   拐点
                  itemStyle: {
                    normal: {
                      color: "#ffe814"
                    },
                    color: "#ffe814",
                    borderColor: "#ff5c00",
                    borderWidth: 1,
                    shadowColor: "#fff",
                    shadowBlur: 0,
                    shadowOffsetY: 0,
                    shadowOffsetX: 0
                  },
                  // 线条
                  lineStyle: {
                    color: "#f3de16",
                    width: 4
                  },
                  // hover
                  emphasis: {
                    scale: true
                  },
                  tooltip: {
                    show: true
                  },
                  label: {
                    normal: {
                      show: true,
                      position: "top",
                      color: "#fff",
                      fontSize: 12,
                      distance: 0
                    }
                  },
                  smooth: true,
                  showSymbol: true,
                  data: numList,
                  type: "line"
                }
              ]
            };
            // 使用刚指定的配置项和数据显示图表。
            leftTop.setOption(option);
          }
        });
    },
    // 地图
    initMap () {
      this.map = new AMap.Map("container", {
        center: this.center,
        zoom: this.zoom,
        showMaker: true
      });
      this.resetDraw();
    },
    // 用保存的数据重新绘制多边形并添加事件
    resetDraw () {
      let that = this;
      var maker = new AMap.Marker({
        icon: icon,
        imageSize: "5px",
        position: that.center
      });
      maker.setMap(that.map);
      let savePolygon = JSON.parse(that.pathList);
      console.log("重绘多边形数据", savePolygon);
      for (let i = 0; i < savePolygon.length; i++) {
        var polygon = new AMap.Polygon(savePolygon[i]);
        polygon.on("click", function (e) {
          console.log("点击了" + i + "个内容", e);
          //   that.dialogVisible = true;
        });
        this.map.add(polygon);
      }
    },
    // 右上角
    rightTop () {
      var that = this;
      that.$http
        .post("/memberActivity/monthServerStastic", {
          streetCode: that.street.streetCode
        })
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            var dayList = response.data.data.dayList;
            var doorList = response.data.data.doorList;
            var storeList = response.data.data.storeList;
            var rightTop = echarts.init(document.getElementById("rightTop"));
            let option = {
              // 图例
              legend: {
                data: ["上门服务数", "到店服务数"],
                top: "25",
                x: "20%",
                textStyle: {
                  color: "#fff",
                  fontSize: 10
                },
                borderColor: "#ffe814",
                type: "plain",
                icon: "roundRect",
                tooltip: {
                  show: true
                }
              },
              grid: {
                left: "15%",
                right: "5%"
              },
              xAxis: {
                type: "category",
                axisLabel: {
                  color: "#6f7aa3"
                },
                axisLine: {
                  //轴线
                  lineStyle: {
                    color: "#747fa7"
                  }
                },
                axisTick: {
                  show: true, //展示刻度线
                  alignWithLabel: true, //刻度居中
                  lineStyle: {
                    //刻度线样式
                    color: "#747fa7"
                  }
                },
                boundaryGap: false,
                data: dayList
              },
              yAxis: {
                axisLabel: {
                  color: "#747fa7"
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "#183578"
                  }
                },
                axisLine: {
                  //轴线
                  lineStyle: {
                    color: "#747fa7"
                  }
                }
              },
              series: [
                {
                  name: "到店服务数",
                  symbolSize: 7,
                  //   拐点
                  itemStyle: {
                    normal: {
                      color: "#ff900d"
                    },
                    color: "#ff900d",
                    borderColor: "#ff5c00",
                    borderWidth: 1,
                    shadowColor: "#fff",
                    shadowBlur: 0,
                    shadowOffsetY: 0,
                    shadowOffsetX: 0
                  },
                  // 线条
                  lineStyle: {
                    color: "#c37019",
                    width: 4
                  },
                  // hover
                  emphasis: {
                    scale: true
                  },
                  tooltip: {
                    show: true
                  },
                  label: {
                    normal: {
                      show: true,
                      position: "top",
                      color: "#fff",
                      fontSize: 12,
                      distance: 0
                    }
                  },
                  smooth: true,
                  showSymbol: true,
                  data: storeList,
                  type: "line"
                },
                {
                  name: "上门服务数",
                  symbolSize: 7,
                  //   拐点
                  itemStyle: {
                    normal: {
                      color: "#00fdf1"
                    },
                    color: "#00fdf1",
                    borderColor: "#ff5c00",
                    borderWidth: 1,
                    shadowColor: "#fff",
                    shadowBlur: 0,
                    shadowOffsetY: 0,
                    shadowOffsetX: 0
                  },
                  // 线条
                  lineStyle: {
                    color: "#00fff3",
                    width: 4
                  },
                  // hover
                  emphasis: {
                    scale: true
                  },
                  tooltip: {
                    show: true
                  },
                  label: {
                    normal: {
                      show: true,
                      position: "top",
                      color: "#fff",
                      fontSize: 12,
                      distance: 0
                    }
                  },
                  smooth: true,
                  showSymbol: true,
                  data: doorList,
                  type: "line"
                }
              ]
            };
            rightTop.setOption(option);
          }
        });
    },
    // one
    one () {
      var that = this;
      that.$http
        .post("/memberActivity/activityStastic", {
          streetCode: that.street.streetCode
        })
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            var data = response.data.data;
            var one = echarts.init(document.getElementById("one"));
            let option = {
              color: [
                "#8913ff",
                "#13f1ff",
                "#4081ff",
                "#ffe814",
                "#3751E6",
                "#FFC722",
                "#886EFF",
                "#85f67a"
              ],
              legend: {
                orient: "vertical",
                left: "left",
                data: ["Apple", "Grapes", "Pineapples", "Oranges", "Bananas"]
              },
              series: [
                {
                  type: "pie",
                  radius: [0, 60],
                  itemStyle: {},
                  label: {
                    normal: {
                      show: true,
                      position: "outside",
                      distance: 0,
                      fontSize: 12,
                      color: "#fff",
                      lineOverflow: "none",
                      // formatter: '{@proportion}'
                      formatter: data => {
                        return data.name + "\n" + "\n" + data.value;
                      }
                    }
                  },
                  data: data
                },
                {
                  type: "pie",
                  radius: [0, 60],
                  itemStyle: {},
                  label: {
                    normal: {
                      show: true,
                      position: "inside",
                      distance: 0,
                      fontSize: 12,
                      color: "#fff",
                      lineOverflow: "none",
                      // formatter: '{@proportion}'
                      formatter: data => {
                        return data.percent.toFixed(0) + "%";
                      }
                    }
                  },
                  data: data
                }
              ]
            };
            one.setOption(option);
          }
        });
    },
    // two
    two () {
      var that = this;
      that.$http
        .post("/memberActivity/ageStastic", {
          streetCode: that.street.streetCode
        })
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            var ageList = response.data.data.ageList;
            var numList = response.data.data.numList;
            var two = echarts.init(document.getElementById("two"));
            let option = {
              tooltip: {},
              grid: {
                left: "15%",
                right: "5%"
              },
              legend: {
                textStyle: {
                  color: "#fff",
                  fontSize: 10
                }
              },
              xAxis: {
                axisLabel: {
                  color: "#6f7aa3",
                  rotate: "310"
                },
                data: ageList
              },
              yAxis: {
                axisLine: {
                  show: false
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "#183578"
                  }
                },
                axisLabel: {
                  color: "#6f7aa3"
                }
              },
              series: [
                {
                  name: "人数",
                  color: "#40ffba",
                  label: {
                    normal: {
                      show: true,
                      position: "top",
                      color: "#fff",
                      fontSize: 12,
                      distance: 0
                    }
                  },
                  type: "bar",
                  data: numList
                }
              ]
            };
            two.setOption(option);
          }
        });
    },
    // three
    three () {
      var that = this;
      that.$http
        .post("/memberActivity/sexStastic", {
          streetCode: that.street.streetCode
        })
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            var data = response.data.data;
            var three = echarts.init(document.getElementById("three"));
            let option = {
              color: ["#4081ff", "#ffa724", '#fc5531'],

              legend: {
                textStyle: {
                  color: "#fff",
                  fontSize: 10
                }
              },
              series: [
                {
                  type: "pie",
                  radius: [0, 60],
                  itemStyle: {},
                  label: {
                    normal: {
                      show: true,
                      position: "outside",
                      distance: 0,
                      fontSize: 12,
                      color: "#fff",
                      lineOverflow: "none",
                      // formatter: '{@proportion}'
                      formatter: data => {
                        return data.name + "\n" + "\n" + data.value;
                      }
                    }
                  },
                  data: data
                },
                {
                  type: "pie",
                  radius: [0, 60],
                  itemStyle: {},
                  label: {
                    normal: {
                      show: true,
                      position: "inside",
                      distance: 0,
                      fontSize: 12,
                      color: "#fff",
                      lineOverflow: "none",
                      // formatter: '{@proportion}'
                      formatter: data => {
                        return data.percent.toFixed(0) + "%";
                      }
                    }
                  },
                  data: data
                }
              ]
            };
            three.setOption(option);
          }
        });
    },
    // four
    four () {
      var that = this;
      that.$http
        .post("/memberActivity/monthSoStastic", {
          streetCode: that.street.streetCode
        })
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            var nameList = response.data.data.nameList;
            var numList = response.data.data.numList;
            var four = echarts.init(document.getElementById("four"));
            let option = {
              tooltip: {},
              grid: {
                left: "15%",
                right: "5%"
              },
              xAxis: {
                axisLabel: {
                  color: "#6f7aa3",
                  rotate: "310"
                },
                data: nameList
              },
              yAxis: {
                axisLine: {
                  show: false
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "#183578"
                  }
                },
                axisLabel: {
                  color: "#6f7aa3"
                }
              },
              series: [
                {
                  name: "人数",
                  itemStyle: {
                    color: params => {
                      let colorList = [
                        "#40ffba",
                        "#ffe814",
                        "#4081ff",
                        "#ffa724",
                        "#00d1ff",
                        "#ff40f7",
                        "#723fc9"
                      ];
                      return colorList[params.dataIndex];
                    }
                  },
                  label: {
                    normal: {
                      show: true,
                      position: "top",
                      color: "#fff",
                      fontSize: 12,
                      distance: 0
                    }
                  },
                  type: "bar",
                  data: numList
                }
              ]
            };
            four.setOption(option);
          }
        });
    },
    // 获取用户统计
    getUserNum () {
      const param = {
        streetCode: this.street.streetCode
      };
      this.$http.post("/memberActivity/indexCount", param).then(res => {
        if (res.data.code == 200) {
          this.UserNum = res.data.data;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.bg_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.title_box {
  width: 450px;
  padding: 0 13px 30px 13px;
}
.dianliang {
  width: 40px;
  height: 5px;
}
.title_img {
  width: 207px;
  height: 25px;
  margin: 0 5px;
}
.head_box {
  background-image: linear-gradient(to bottom, #050a35, #14326d);
  height: 66px;
  position: relative;
}
.address {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: #9fcffd;
  margin-top: 10px;
}
.line_img {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 693px;
}
.six_box {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  padding-top: 10px;
}
.one_box {
  border: 2px solid #183578;
  padding: 2px 8px;
  flex-direction: column;
  position: relative;
  margin: 0 10px;
}
.left_top {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 5px;
  height: 5px;
  border-top: 3px solid #088fbf;
  border-left: 3px solid #088fbf;
}
.left_bottom {
  position: absolute;
  bottom: -2px;
  left: -2px;
  width: 5px;
  height: 5px;
  border-bottom: 3px solid #088fbf;
  border-left: 3px solid #088fbf;
}
.right_top {
  position: absolute;
  right: -2px;
  top: -2px;
  width: 5px;
  height: 5px;
  border-top: 3px solid #088fbf;
  border-right: 3px solid #088fbf;
}
.right_bottom {
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 5px;
  height: 5px;
  border-right: 3px solid #088fbf;
  border-bottom: 3px solid #088fbf;
}
/deep/.el-input__inner {
  background-color: transparent;
  color: #3e4e7d;
  border: 3px solid #183578;
  padding-left: 2px;
}
/deep/ .el-select {
  width: 90px;
  border: #183578;
}
.right_box {
  padding: 0 5px;
  font-size: 12px;
}
.data_box {
  margin: 40px 0;
}
.map_box {
  position: relative;
}
.map_title {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -100%);
}
.echarts_title {
  padding: 10px;
  color: #fff;
  font-size: 12px;
  img {
    width: 40px;
    margin-left: 10px;
  }
}
/deep/ .amap-icon img {
  width: 25px;
}
</style>
